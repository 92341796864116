import mixins from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mainFilter from '../../components/filter/index.vue'
import moment from 'moment'
import { camelCase, debounce, snakeCase } from 'lodash'

export default {
  name: 'user-students-home-works-course-detail',
  mixin: [mixins],
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY')
    }
  },
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      openDialogView: false,
      editedItem: null,
      filter: ''
    }
  },
  components: {
    mainFilter,
  },
  computed: {
    ...mapGetters({
      list: 'homeWorkResult/list',
      limit: 'homeWorkResult/limit',
      skip: 'homeWorkResult/skip',
      isListLoading: 'homeWorkResult/isListLoading',
      listLength: 'homeWorkResult/listLength',
      isLoading: 'homeWorkResult/isLoading',
      setting: 'courses/setting'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Started at',
          value: 'startedAt'
        },
        {
          text: 'Module',
          value: 'module'
        },
        {
          text: 'Points',
          value: 'points'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.updateFilter({ user: this.$route.params.id })

    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'homeWorkResult/GET_LIST',
      remove: 'homeWorkResult/DELETE'
    }),
    ...mapMutations({
      setCurrentItem: 'homeWorkResult/SET_ITEM',
      setListContent: 'homeWorkResult/SET_LIST',
      setList: 'homeWorkResult/SET_LIST',
      changeFixBody: 'system/FIX_BODY',
      changeSkip: 'homeWorkResult/CHANGE_SKIP',
      changeSort: 'homeWorkResult/CHANGE_SORT'
    }),
    customSort (items, nameField, isDesc) {
      const toSnakeField = snakeCase(nameField[0])
      let sortData = ''

      if (!nameField.length) {
        this.changeSort(sortData)
        this.changeSkip(0)

        // this.fetchList(this.$route.params)

        return items
      }

      if (nameField) {
        if (isDesc) {
          sortData = `&sorting[field]=${toSnakeField}&sorting[direction]=desc`
        } else {
          sortData = `&sorting[field]=${toSnakeField}&sorting[direction]=asc`
        }
      } else {
        sortData = ''
      }

      this.changeSort(sortData)
      this.changeSkip(0)

      return items
    },
    toCamelCase (str) {
      return camelCase(str)
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.$route.params)
    },
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    updateFilter (params) {
      this.filter = params
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      item.course_id = this.$route.params.course_id
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList(this.$route.params)
    }, 1000)
  },
  destroyed () {
    this.setListContent([])
    this.setList([])
  }
}
