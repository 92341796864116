import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import validation from '@/mixins/validation'
import mixins from '@/mixins'
import moment from 'moment'

export default {
  name: 'filter-home-works',
  mixin: [mixins, validation],
  data () {
    return {
      dateRangeText: '',
      menuFrom: false,
      menuTo: false,
      search: '',
      searchInput: '',
      filter: {
        dateFrom: '',
        dateTo: '',
        status: '',
        user: '',
        module: '',
        isChecking: true
      }
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      filters: 'homeWorkResult/filters',
      currentCourse: 'homeWorkResult/currentCourse',
      isLoadingCourse: 'homeWorkResult/isLoadingCourse',

      basicUsers: 'user/basicUsers',
      basicUsersLoading: 'user/basicUsersLoading'
    }),
    typesHomeWork () {
      return [
        {
          name: 'Testing',
          id: 1
        },
        {
          name: 'Detailed response',
          id: 2
        }
      ]
    }
  },
  created () {
    this.changeFilterLocal(this.$route.params.id, 'user')
    this.setFilter()
    this.fetchCourse(this.$route.params)
  },
  methods: {
    ...mapActions({
      fetchList: 'homeWorkResult/GET_LIST',
      fetchUsersBasic: 'user/GET_BASIC_USERS',
      fetchCourse: 'homeWorkResult/GET_COURSE'
    }),
    ...mapMutations({
      changeFilter: 'homeWorkResult/CHANGE_FILTER'
    }),
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 500),
    setFilter () {
      Object.assign(this.filter, this.filters)
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // users
    changeFilterLocal (val, type) {
      this.changeFilter({
        value: val,
        type: type
      })
      this.fetchList(this.$route.params)
    }
  },
  destroyed () {
    this.changeFilter()
  }
}
